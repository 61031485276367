
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import React, { useEffect, useState,useCallback } from 'react';
import {UNITY_URLS} from './utils/config';
import "react-tooltip/dist/react-tooltip.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import FileDropForm from "./components/modal"
import { useLocation } from 'react-router-dom';
import WebViewer from './components/WebViewer'
import OpenViduApp from './components/openvidu/openVidu';


const App = () => {
     
  const [trigger, setTriggerLeave] = useState(false);
  const [conferenceUID, setConferenceUID] = useState()
  const [u, setusern] = useState()
  const [m, setmeetingn] = useState()
  const [r, setrole] = useState('')
  const [isOpenbtn, setIsopen] = useState(false)
  const[isCall,setCall] = useState()
  const [progress, setProgress] = useState(0);
  const [loadConfig, setLoadConfig] = useState(false);
  //static
  const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression,isLoaded } = useUnityContext(UNITY_URLS);
  const [webUrl, setWebUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [UserValue, setValue] = useState('');
  const [metaverse_id, setMeta] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [error, setError] = useState(null);
  const[iframeData,setiframeData] = useState()
  const[configData,setConfigData] = useState()
  const [Init, setInit] = useState(false)
  const[startSession,setStartSession] = useState(false)
  // const [devicePixelRatio, setDevicePixelRatio] = useState(
  //   window.devicePixelRatio
  // );


  // var ratio = 1920 / window.screen.width;
  // if(ratio <= 1.2) ratio = window.devicePixelRatio;

  //const [devicePixelRatio, setDevicePixelRatio] = useState(ratio);

  const [devicePixelRatio, setDevicePixelRatio] = useState(() => {
    let ratio = 1920 / window.screen.width;
    if (ratio <= 1.2) ratio = window.devicePixelRatio;
    return ratio;
  });

  const location = useLocation();
  const [allMessages, setAllMessages] = useState({});
  const [openPromot,setOpenPromot] = useState(false);
  

  //Dynamic
  // const {
  //   unityProvider,
  //   sendMessage,
  //   addEventListener,
  //   removeEventListener,
  //   loadingProgression,
  //   isLoaded
  // } = useUnityContext({
  //   loaderUrl: localStorage.getItem('loaderUrl'),
  //   frameworkUrl: localStorage.getItem('frameworkUrl'),
  //   dataUrl: localStorage.getItem('dataUrl'),
  //   codeUrl: localStorage.getItem('codeUrl'),
  //   streamingAssetsUrl: localStorage.getItem('streamingAssetsUrl')
    
  // });

  useEffect(() => {
    const handleResize = () => {
      // var ratio = 1920 / window.screen.width;
      // if(ratio <= 1.2) ratio = window.devicePixelRatio;

      let ratio = 1920 / window.screen.width;

      if (ratio <= 1.2) {
        ratio = Math.min(window.devicePixelRatio, 1.2); 
      }
        // setDevicePixelRatio(1920 / window.devicePixelRatio);
        console.log(ratio)
        setDevicePixelRatio(ratio);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleFileAdded = (newFile) => {
    setFile(newFile);
    console.log(newFile)
  };

  
const openModal = (url) => {
  //setWebUrl(url)
  setWebUrl(url);
  setModalIsOpen(true);
};

const closeModal = () => {
  setModalIsOpen(false);
  setWebUrl('');
};



const DismissCall = useCallback(()=> {
  setOpenPromot(false)
},[])


  const loadingPercentage = Math.round(loadingProgression * 100);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');
    const metaId = searchParams.get('metaverse_id');
    console.log("iFrameData:"+ JSON.stringify(iframeData))
   if(iframeData){
      // fetch('https://api.builder.holofair.io/api/config/client?metaverse_id=3')
      fetch('/api/config/client?metaverse_id=3')
        .then(response => response.json())
        .then(fetchedData => {
          //console.log(fetchedData);
          fetchedData["user_id"] = iframeData?.user_id || userId;
          fetchedData["metaverse_id"] = iframeData?.metaverse_id || metaId || "3" ;
          setConfigData(fetchedData);
        })
        .catch(error => console.error('Error fetching the JSON file:', error));
  }
  
  if(metaId){
    fetch('/api/config/client?metaverse_id=3')
    //fetch('https://api.builder.holofair.io/api/config/client?metaverse_id=3')
    .then(response => response.json())
    .then(fetchedData => {
      // console.log(fetchedData);
      fetchedData["user_id"] = userId || 1;
      fetchedData["metaverse_id"] = metaId || 3;
      setConfigData(fetchedData);
     // console.log(fetchedData);
    })
    .catch(error => console.error('Error fetching the JSON file:', error));

  }
    
  }, [iframeData, iframeData?.metaverse_id, iframeData?.user_id, location.search, metaverse_id]);



  // useEffect(()=> {
  
        
  //       if(isLoaded && configData && Init === false){

  //         const searchParams = new URLSearchParams(location.search);
  //         const userId = searchParams.get('user_id');
  //         const metaId = searchParams.get('metaverse_id');
          
  //         const configJSON = {
  //           "metaverse_id": metaId || "3",
  //           "user_id": configData?.user_id || userId,
  //           "APIAddress":"https://api.builder.holofair.io/api/addressables/{metaverse_id}/{platform}",
  //           "Host":"",
  //           "Port":"",
  //           "MaxClients":"256",
  //           "UserProfileAPIGETAddress":"https://majidalfuttaim.enterprise.holofair.io/api/user_profile?user_id=",
  //           "AnalyticsStartedAPI":"https://api.builder.holofair.io/api/interactions/add/started?metaverse_id={metaverse_id}",
  //           "AnalyticsEndedAPI":"https://api.builder.holofair.io/api/interactions/update/ended?metaverse_id={metaverse_id}",
  //           "AnalyticsImmediateAPI":"https://api.builder.holofair.io/api/interactions/add/immediate?metaverse_id={metaverse_id}",
  //           "useHathora":"True",
  //           //"data":iframeData || {}
  //         };
  //         //console.log(configData)
  //         sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
  //         setiframeData(null)

  //               setTimeout(() => {
  //                 if(iframeData === null)
  //                   setInit(true)
  //               }, 1000);
  //       }

  //       if(Init === true && iframeData){
  //           sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(iframeData));
  //           setiframeData(null);
          
  //       }
       
  

  // },[UserValue, iframeData, isCall, isLoaded, loadConfig, location.search, m, progress, record, removeEventListener, sendMessage, u, configData, metaverse_id, Init])
  
  const startCall = () => {
    setusern("ayas")
    setmeetingn("test")
    setIsopen(true)
    setStartSession(true)
    localStorage.setItem("join","")
}


  useEffect(() => {
    if (isLoaded && configData && !Init) {
      //console.log("######A")
      // if(Init === false){
      // sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
      // }
     
  
      //console.log("######B")
      setTimeout(() => {
       
        setiframeData(null);  
          setInit(true);
        
      }, 1000);
    }
  }, [isLoaded, configData, Init, location.search, sendMessage, iframeData]);

  
  
  useEffect(() => {
    if (Init === false && iframeData) {
      //console.log("######2")
      sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(iframeData));
      setiframeData(null); 
    }
  }, [Init, iframeData, sendMessage]);

  useEffect(() => {
    if (Init === true && iframeData) {
      sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(iframeData));
      setiframeData(null); 
    }
  }, [Init, iframeData, sendMessage]);



  useEffect(() =>{
    if(!iframeData && isLoaded && configData && Init === false){
       sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
     }
     
  })



  useEffect(()=>{
    localStorage.setItem('ov','');
   
  })
  const unityCtrlOff = () => {
    console.log('clicked off');
    sendMessage("App","Lock",)
  };

  const unityCtrlOn = () => {
    console.log('clicked on');
    sendMessage("App","Unlock",)
  };


const getValue = useCallback((value)=>{
  console.log("Received from Unity: ",value)
  openModal(value);
},[]);


const handleMessageData = useCallback((event) => {
  const message = event.data;
 // console.log(message);

 
  if (message.type === 'holofair') {
      console.log('Received holofair data:', message.data);

      
      setAllMessages((prevMessages) => ({
          ...prevMessages,
          holofair: message.data
      }));

      // setAllMessages((prevMessages) => ({
      //   ...prevMessages,
      //   holofair: {
      //       ...(prevMessages.holofair || {}),  
      //       ...message.data  
      //   }
      // }));
  }
 
}, []);


const connectOpenvidu = useCallback( async (username,roomname)=> {
  console.log('Connecting')
  localStorage.setItem("progress",progress)
  setCall(false)
  setLoadConfig(true)
  setusern('')
  setmeetingn('')
  try {
    console.log(`OpenVidu`, username, roomname)
    setusern(username)
    setmeetingn(roomname)
    console.log(progress)
  

    try {
      if(progress >= 100){
       // console.log("what is it")
        // setProgress(0)
        // setIsopen(true)
        // setCall(true)
        // setIsopen(true)
        // setStartSession(true)
        // localStorage.setItem("join","")
       
      }

    } catch (error) {
      console.log('Err')
      console.log(error);
    }
  } catch (e) {
    console.error('Connection failed, handle error', e);
  }

},[progress])


const handleLeaveSession = (e) => {
  setTriggerLeave(e); 
};


const joinOVfunc = useCallback((username, roomname)=>{

  console.log("My data",username, roomname)
  connectOpenvidu(username,roomname);

},[connectOpenvidu]);


const joinCall = useCallback(() =>{
  console.log(u, m)
  setOpenPromot(false)
  joinOVfunc(u, m);
},[joinOVfunc, m, u])

const showPromot = (u,r) =>{
  setusern(u);
  setmeetingn(r)
  setOpenPromot(true)
}

// useEffect(() => {

//  // console.log("Updated allMessages:", allMessages);

//   setiframeData(allMessages);
// }, [allMessages]);


useEffect(() => {
 // console.log("Updated allMessages:", allMessages);
  //console.log(allMessages.holofair)

  
  setiframeData(allMessages.holofair);
}, [allMessages]); 


const onLoadAction = () => {
  // isOpenbtn === true ? setIsopen(false) : setIsopen(true);
  localStorage.setItem('callstatus','')
}

const leaveOVfunc = useCallback(()=>{
  console.log("Leaving now")
  setOpenPromot(false);
  
},[])



useEffect(()=> {


    if(localStorage.getItem("progress") !== '') {
      localStorage.setItem("progress","") 
      setProgress(0)
    }

    const updateProgress = () => {
      setProgress(oldProgress => {
          if (oldProgress === 100) {
              clearInterval(timer);
              setProgress(0)
              setIsopen(true)
              setCall(true)
              setIsopen(true)
              setStartSession(true)
              localStorage.setItem("join","")
              
              return 100;
          }
          
          return Math.min(oldProgress + 10, 100);
      });

  };

  const timer = isCall === false ?setInterval(updateProgress, 200):0;

  window.addEventListener('message', handleMessageData);
  addEventListener("Display",getValue);
  addEventListener("JoinOpenVidu",showPromot);
  addEventListener("LeaveOpenVidu",leaveOVfunc);
  window.addEventListener('beforeunload', onLoadAction);

  return () => {
     window.addEventListener('message', handleMessageData);
     removeEventListener("Display",getValue);
     removeEventListener("JoinOpenVidu",showPromot);
     removeEventListener("LeaveOpenVidu",leaveOVfunc);
     window.removeEventListener('beforeunload', onLoadAction);
     clearTimeout(timer);
     //LeaveOpenVidu
     //JoinOpenVidu
  }


},[addEventListener, getValue, handleMessageData, isCall, showPromot, joinOVfunc, progress, removeEventListener, leaveOVfunc])

function funcOpen(e){
  setIsopen(e)
}


  return (
    <>
  
      <React.StrictMode>
      {/* <AppBase> */}
      <div onClick={unityCtrlOff}>
      {/* <FileDropForm onFileSelect={handleFileSelect}  myEdit={addEventListener} myCall={sendMessage} removeEvent={removeEventListener} /> */}
      </div>
      {/* <ChatApp  myChat={sendMessage} myEvent={addEventListener} myCleanUp={removeEventListener} /> */}
        {/* {isCall === false  && progress < 100 &&
          <div className='spinnerCall'>
              <div>
                <progress style={{width:"350px",height:"5px"}} value={progress} max="100"></progress>
                <p style={{textAlign:"center", background: "#9A2F23",width: "45px",margin: "auto",color: "#fff", borderRadius: "50px"}}
                >{progress}%</p>
              </div>
          </div>
           }  */}
         
      {/* {isOpenbtn && conferenceUID &&
        <Content  update={funcOpen} usern={u} mname={m} id={conferenceUID} role={r}  videoTag={sendMessage}/> 
      }  */}

      {isOpenbtn && u && m &&
        <OpenViduApp 
          update={funcOpen}
          triggerLeave={handleLeaveSession}
          start={startSession}
          userName={u} sessionName={m} 
          sendMessage={sendMessage}
          /> 
      } 
      
      <div onClick={unityCtrlOff}>
       <FileDropForm
        // isOpen={isModalOpen}
        // onClose={() => setIsModalOpen(false)}
        onFileAdded={handleFileAdded}
        file={file} 
        myEdit={addEventListener} 
        myCall={sendMessage}
        removeEvent={removeEventListener}
      />
      </div>
   
 
   {isLoaded === false && (
    <>
     <div className="progress-overlay">
      </div> 
       <div className="progress-bar">
       <h2>{loadingPercentage}%</h2>
       <progress style={{margin:"0 auto",width:"80%",height:"50%",border:"1px solid #8A1538"}} id="file2" value={loadingPercentage} max="100">({loadingPercentage}%)</progress>
       </div>
       </>
     )} 

    <WebViewer isOpen={modalIsOpen} onClose={closeModal} url={webUrl} />

   {/* <div>
       <button onClick={startCall}>Start call</button>
    </div>   */}

{openPromot &&
   <div className="button-container">
      
        <button className="dismiss-btn" onClick={DismissCall}>Dismiss</button>
        <button className="join-btn" onClick={joinCall}>
            Join the call
            <span className="call-icon">
            
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 2.75C0.25 2.08696 0.513392 1.45107 0.982233 0.982233C1.45107 0.513392 2.08696 0.25 2.75 0.25L3.89333 0.25C4.61 0.25 5.235 0.738333 5.40917 1.43333L6.33 5.11917C6.40462 5.4175 6.38955 5.73122 6.28667 6.02102C6.18379 6.31082 5.99769 6.56383 5.75167 6.74833L4.67417 7.55667C4.56167 7.64083 4.5375 7.76417 4.56917 7.85C5.03948 9.12904 5.78216 10.2906 6.74579 11.2542C7.70941 12.2178 8.87096 12.9605 10.15 13.4308C10.2358 13.4625 10.3583 13.4383 10.4433 13.3258L11.2517 12.2483C11.4362 12.0023 11.6892 11.8162 11.979 11.7133C12.2688 11.6105 12.5825 11.5954 12.8808 11.67L16.5667 12.5908C17.2617 12.765 17.75 13.39 17.75 14.1075L17.75 15.25C17.75 15.913 17.4866 16.5489 17.0178 17.0178C16.5489 17.4866 15.913 17.75 15.25 17.75L13.375 17.75C6.12667 17.75 0.25 11.8733 0.25 4.625L0.25 2.75Z" fill="white"/>
                    </svg>
                    
            
            </span>
        </button>
    </div>
  }


      <div onClick={unityCtrlOn} >
     <Unity style={{ width: "100%",height:"100vh" , visibility: isLoaded ? "visible" : "hidden"}} unityProvider={unityProvider}  devicePixelRatio={devicePixelRatio} />
     </div> 

      {/* </AppBase> */}
      </React.StrictMode>
    </>
  )
}

export default App;
import React, { useState, useEffect, useCallback,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faUsers ,faDisplay,faStop} from '@fortawesome/free-solid-svg-icons';
import { Room, RoomEvent, createLocalTracks } from 'livekit-client';
import './App.css';
import { Dropdown } from 'react-bootstrap';
import VideoComponent from "./components/VideoComponent2";
import VideoComponentLocal from "./components/VideoComponentLocal";
import AudioComponent from "./components/AudioComponent1";
import {UNITY_URLS} from '../../utils/config';
import { useUnityContext } from "react-unity-webgl";

// const APPLICATION_SERVER_URL = "https://dolbyauth.holofair.io"; 
// const LIVEKIT_URL = "wss://mediastream.holofair.io/"; 

const APPLICATION_SERVER_URL = window.APP_CONFIG?.APPLICATION_SERVER_URL; 
const LIVEKIT_URL = window.APP_CONFIG?.LIVEKIT_URL; 


const VideoSession = ({start,update,sendMessage,userName,sessionName}) => {
  
  const [room, setRoom] = useState(null);
  const videoRef = useRef(null);
  const {addEventListener, removeEventListener} = useUnityContext(UNITY_URLS);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [localTracks, setLocalTracks] = useState({ audioTrack: null, videoTrack: null });
  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoStopped, setIsVideoStopped] = useState(false);
  const [participantName, setParticipantName] = useState("Participant" + Math.floor(Math.random() * 100));
  const [roomName, setRoomName] = useState(sessionName || "TestRoom");
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [showParticipantsList, setShowParticipantsList] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [localTrack, setLocalTrack] = useState(undefined);
  const [remoteTracks, setRemoteTracks] = useState([]);
  //const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [isLocalAudioEnabled, setIsLocalAudioEnabled] = useState(false);

  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null); // Local screen share stream
  const localScreenVideoRef = useRef(null);
  const screenTrackRef = useRef(null); 
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);




  const leaveRoom = useCallback(async()=> {
    await room?.disconnect();
    update(false)
    setRoom(undefined);
    setLocalTrack(undefined);
    setIsAudioMuted(false)
    setAudioEnabled(false)
    setRemoteTracks([]);
    localStorage.setItem("join","")
    sendMessage("VideoWebGL", "InitializeRenderTexture", "");
    
  },[room, sendMessage, update])


  const joinRoom = useCallback( async() => {
    const room = new Room();
    setRoom(room);
  
    console.log("Room instance created");
  
    // Handle Track Subscribed
    room.on(RoomEvent.TrackSubscribed, (_track, publication, participant) => {
      console.log("Track subscribed:", {
        participant: participant.identity,
        trackSid: publication.trackSid,
      });
      setRemoteTracks((prev) => [
        ...prev,
        {
          trackPublication: publication,
          participantIdentity: participant.identity || "Unnamed Participant",
          isAudioEnabled: _track.kind === "audio" ? !_track.isMuted : undefined,
          isVideoEnabled: _track.kind === "video" ? !_track.isMuted : undefined,
        },
      ]);
    });
  
    // Handle Track Unsubscribed
    room.on(RoomEvent.TrackUnsubscribed, (_track, publication) => {
      console.log("Track unsubscribed:", { trackSid: publication.trackSid });
      setRemoteTracks((prev) =>
        prev.filter((track) => track.trackPublication.trackSid !== publication.trackSid)
      );
    });
  
    // Handle Remote Audio Muted
    room.on(RoomEvent.TrackMuted, (track, participant) => {
      if (participant !== room.localParticipant && track.kind === "audio") {
        console.log("Remote audio muted:", participant.identity);
        setRemoteTracks((prev) =>
          prev.map((remoteTrack) =>
            remoteTrack.participantIdentity === participant.identity
              ? { ...remoteTrack, isAudioEnabled: false }
              : remoteTrack
          )
        );
      }
    });
  
    // Handle Remote Audio Unmuted
    room.on(RoomEvent.TrackUnmuted, (track, participant) => {
      if (participant !== room.localParticipant && track.kind === "audio") {
        console.log("Remote audio unmuted:", participant.identity);
        setRemoteTracks((prev) =>
          prev.map((remoteTrack) =>
            remoteTrack.participantIdentity === participant.identity
              ? { ...remoteTrack, isAudioEnabled: true }
              : remoteTrack
          )
        );
      }
    });
  
    // Handle Local Audio/Video Toggles
    room.localParticipant.on(RoomEvent.TrackMuted, (track) => {
      if (track.kind === "audio") {
        console.log("Local audio muted");
        setIsLocalAudioEnabled(false);
      }
    });
  
    room.localParticipant.on(RoomEvent.TrackUnmuted, (track) => {
      if (track.kind === "audio") {
        console.log("Local audio unmuted");
        setIsLocalAudioEnabled(true);
      }
    });
  
    // Handle Remote Video Muted/Unmuted
    room.on(RoomEvent.TrackMuted, (track, participant) => {
      if (participant !== room.localParticipant && track.kind === "video") {
        console.log(`${participant.identity} video muted`);
        setRemoteTracks((prev) =>
          prev.map((remoteTrack) =>
            remoteTrack.participantIdentity === participant.identity
              ? { ...remoteTrack, isVideoEnabled: false }
              : remoteTrack
          )
        );
      }
    });
  
    room.on(RoomEvent.TrackUnmuted, (track, participant) => {
      if (participant !== room.localParticipant && track.kind === "video") {
        console.log(`${participant.identity} video unmuted`);
        setRemoteTracks((prev) =>
          prev.map((remoteTrack) =>
            remoteTrack.participantIdentity === participant.identity
              ? { ...remoteTrack, isVideoEnabled: true }
              : remoteTrack
          )
        );
      }
    });
  
    try {
      const token = await getToken(roomName, participantName);
      console.log("Generated token:", token);
  
      await room.connect(LIVEKIT_URL, token, {
        audio: false,
        video: false,
      });
      console.log("Connected to room successfully.");
  
      await room.localParticipant.enableCameraAndMicrophone();
  
      // Local Video Track
      const videoTrack = room.localParticipant.videoTrackPublications.values()?.next().value?.videoTrack;
      console.log("Local video track state:", videoTrack?.isMuted ? "Muted" : "Unmuted");
  
      if (isVideoEnabled === false) {
        videoTrack.mute();
        console.log(videoTrack?.isMuted ? "Muted" : "Unmuted");
        setLocalTrack(videoTrack);
      }
  
      // Local Audio Track
      const audioTrack = room.localParticipant.audioTrackPublications.values()?.next().value?.audioTrack;
      console.log("Local audio track state:", audioTrack?.isMuted ? "Muted" : "Unmuted");
  
      if (isLocalAudioEnabled === false) {
        audioTrack.mute();
        console.log(audioTrack?.isMuted ? "Muted" : "Unmuted");
        setIsLocalAudioEnabled(audioTrack);
      }
  
      // Reset timer
      setTimeElapsed(0);
      if (timerIntervalId) clearInterval(timerIntervalId);
  
      const intervalId = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
  
      setTimerIntervalId(intervalId);
    } catch (error) {
      console.error("Error connecting to the room:", error.message);
      await leaveRoom();
    }
  },[isLocalAudioEnabled, isVideoEnabled, leaveRoom, participantName, roomName, timerIntervalId]);
  
  


   






  useEffect(()=>{
    if(remoteTracks)
    console.log(remoteTracks)
  },[remoteTracks])




  useEffect(() =>{
    if(start === true && localStorage.getItem("join") === ""){
      localStorage.setItem("join","yes")
     joinRoom();
    }
 },[joinRoom, participantName, roomName, start])


 const leaveOVfunc = useCallback(()=>{
  console.log("Leaving now")
  leaveRoom();
  
},[leaveRoom])


useEffect(() =>{
  addEventListener("LeaveOpenVidu",leaveOVfunc);
  const handleBeforeUnload = () => leaveRoom();
  window.addEventListener('beforeunload', handleBeforeUnload);
  return () => {
    removeEventListener("LeaveOpenVidu",leaveOVfunc);
   window.removeEventListener('beforeunload', handleBeforeUnload);
   }
  
},[addEventListener, leaveOVfunc, leaveRoom, removeEventListener])


  async function getToken(roomName, participantName) {
    const response = await fetch(APPLICATION_SERVER_URL + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        roomName: roomName,
        participantName: participantName
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`Failed to get token: ${error.errorMessage}`);
    }

    const data = await response.json();
    return data.token;
  }



  const toggleVideo = async () => {
    if (room && room.localParticipant) {
      const nextState = !isVideoEnabled; // Predict the next state
      setIsVideoEnabled(nextState); // Update state immediately for the UI
  
      try {
        await room.localParticipant.setCameraEnabled(nextState); // Toggle camera
      } catch (error) {
        console.error("Error toggling video:", error);
        setIsVideoEnabled(!nextState); // Rollback in case of error
      }
    }
  };


  const toggleAudio = () => {
    if (room && room.localParticipant) {
      if (isLocalAudioEnabled) {
        room.localParticipant.setMicrophoneEnabled(false);
      } else {
        room.localParticipant.setMicrophoneEnabled(true);
      }
      setIsLocalAudioEnabled(!isLocalAudioEnabled);
    }
  };


  const formatTimeElapsed = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');
  
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };


  function getParticipantName(participant) {
    return participant.identity || participant || "Unnamed Participant";
  }

 

  const drawToCanvas = (videoElement) => {
    const canvas = canvasRef.current;
    if (canvas && videoElement) {
      const ctx = canvas.getContext("2d");
      const { videoWidth, videoHeight } = videoElement;

      // Set canvas dimensions to match the video feed
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      // Continuously draw the video frame onto the canvas
      const drawFrame = () => {
        if (!videoElement.paused && !videoElement.ended) {
          ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
          requestAnimationFrame(drawFrame);
        }
      };
      drawFrame();
    }
    if(canvasRef.current !== null){
      sendMessage("VideoWebGL", "InitializeRenderTexture", "canvasVideo");
    }
  };


  const startScreenShare = async () => {
    try {
      if(isScreenSharing) {
        stopScreenShare()
       
      }
      else{
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true, 
      });
      const videoTrack = stream.getVideoTracks()[0];
      setScreenShareStream(stream);
      setIsScreenSharing(true);
      await room.localParticipant.publishTrack(videoTrack, {
        name: "screen", 
      });
    

    videoTrack.addEventListener("ended", () => {
      console.log("Screen sharing stopped by the browser.");
      console.log("Screen sharing stopped by the user (via browser UI).");
      stopScreenShare()
      room.localParticipant.unpublishTrack(videoTrack);
      setIsScreenSharing(false);
      setScreenShareStream(null);
      
    });

  }

    } catch (error) {
      console.error("Error starting screen share:", error);
    }
  };


  // Stop screen sharing
  const stopScreenShare = () => {
    if (screenShareStream) {
      screenShareStream.getTracks().forEach((track) => track.stop());
      // room.localParticipant.publishTrack(screenShareStream, {
      //   name: "screen", 
      // });

      room.localParticipant.unpublishTrack(screenShareStream);
  
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }

      setScreenShareStream(null);
      setIsScreenSharing(false);
    }
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };


  useEffect(() => {
    if (videoRef.current && screenShareStream) {
        videoRef.current.srcObject = screenShareStream;
        videoRef.current.play(); 
        setIsLoading(false);
    }
}, [screenShareStream]);

  

  return (
    <div className="container" style={{
      position: "fixed",
      bottom: "150px",
      left: "50%",
      transform: "translateX(-50%)"
    }}>

  
      {!room && !localParticipant ? (
        // <div id="join">
        //   <div id="join-dialog" className="jumbotron vertical-center">
        //     <h1>No Session yet</h1>
        //     <button className="btn btn-primary" onClick={joinRoom}>Join Room</button>
        //   </div>
        // </div>
        <></>
      ) : (
    <div id="session" style={{ position: "", width: "100%", height: "100%" }}>

       <canvas id="canvasVideo" ref={canvasRef} style={{ border: "1px solid black", margin: "20px auto" , width:"10px", display:"block",visibility:"hidden"}} />
         


      <div id="video-container" className="grid-container" style={{ visibility: showParticipantsList ? 'visible' : 'hidden' }}>
           

                


               <br></br>
                


               {isScreenSharing && (
                 <div className="stream-container" key="local" >

                      <video
                        // ref={(el) => {
                        //   if (el && screenShareStream) {
                        //     el.srcObject = screenShareStream;
                        //     el.play(); 
                        //   }
                        // }}
                        className={isLoading ? "loading" : ""}
                        ref={videoRef}
                        autoPlay
                        playsInline
                        onClick={(e) => drawToCanvas(e.target)} 
                        style={{ width: "250px", cursor: "pointer",background:"#000" }}
                      ></video>

                 </div>
                
              )}


            

<>
  {/* Local Participant */}
  {localTrack && (
    <div className="stream-container" key="local">
      {/* Display local video if enabled */}
      {isVideoEnabled ? (
        <VideoComponentLocal
          track={localTrack}
          participantIdentity={`${participantName}`}
          local={true}
          isVideoEnabled={isVideoEnabled}
          onVideoClick={drawToCanvas}
        />
      ) : (
        // Display placeholder if local video is not enabled
        <div
          className="video-container no-video"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#1B1B1BFF", // Placeholder background
          }}
        >
          <p style={{ fontSize: "16px", color: "#666" }}>
            {participantName}
          </p>
        </div>
      )}

      {/* Audio Icon for Local */}
      <div className="audio-icon">
        {isLocalAudioEnabled ? (
          <FontAwesomeIcon icon={faMicrophone} />
        ) : (
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        )}
      </div>
    </div>
  )}

  {/* Remote Participants */}
  {[...new Set(remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity))].map(
    (participantIdentity) => {
      // Check if the participant has an enabled video track
      const videoTrack = remoteTracks.find(
        (remoteTrack) =>
          remoteTrack.participantIdentity === participantIdentity &&
          remoteTrack.trackPublication.kind === "video" &&
          remoteTrack.trackPublication.videoTrack
      );

      // Check if the participant has an enabled audio track
      const audioTrack = remoteTracks.find(
        (remoteTrack) =>
          remoteTrack.participantIdentity === participantIdentity &&
          remoteTrack.trackPublication.kind === "audio" &&
          remoteTrack.trackPublication.audioTrack
      );

      return (
        <div className="stream-container" key={participantIdentity}>
          {videoTrack && videoTrack.isStarted ? (
            <VideoComponent
              track={videoTrack.trackPublication.videoTrack}
              participantIdentity={participantIdentity}
            />
          ) : (
            // Display placeholder if the participant has no video track
            <div
              className="video-container no-video"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#1B1B1BFF", // Placeholder background
              }}
            >
              <p style={{ fontSize: "16px", color: "#666" }}>
                {getParticipantName(participantIdentity)}
              </p>
            </div>
          )}

          {/* Audio Stream */}
          {audioTrack && (
            <AudioComponent
              key={audioTrack.trackPublication.trackSid}
              track={audioTrack.trackPublication.audioTrack}
              isAudioEnabled={audioTrack.isAudioEnabled}
            />
          )}

          {/* Audio Icon */}
          <div className="audio-icon">
            {remoteTracks.some(
              (remoteTrack) =>
                remoteTrack.participantIdentity === participantIdentity &&
                remoteTrack.isAudioEnabled
            ) ? (
              <FontAwesomeIcon icon={faMicrophone} />
            ) : (
              <FontAwesomeIcon icon={faMicrophoneSlash} />
            )}
          </div>
        </div>
      );
    }
  )}
</>







       
              
        </div>
                        

          <div style={{ width: "900px", bottom: "", position: "absolute", left: "50%", transform: "translate(-48%, 5%)" }}>
            <div className='toolbar2'>
              <button onClick={toggleVideo} title="Enable/Disable Video" className='ops'>
                <FontAwesomeIcon icon={isVideoEnabled ? faVideo : faVideoSlash} />
              </button>
              <button onClick={toggleAudio} title="Mute/Unmute Audio" className='ops'>
                <FontAwesomeIcon icon={isLocalAudioEnabled ? faMicrophone : faMicrophoneSlash} />
              </button>

              {/* <button onClick={() => setShowParticipantsList(!showParticipantsList)} className='ops' title="Participants">
                <FontAwesomeIcon icon={faUsers} />
              </button> */}

              {/* <button onClick={handleShareScreen} className='ops' title="Share Screen">
                <FontAwesomeIcon icon={faDisplay} />
              </button> */}


                <button
                  onClick={startScreenShare}
                  className="ops"
                  title={isScreenSharing ? "Stop Sharing" : "Share Screen"}
                >
                  {isScreenSharing ? (
                    <FontAwesomeIcon icon={faStop} /> // Replace with your stop icon
                  ) : (
                    <FontAwesomeIcon icon={faDisplay} /> // Replace with your share screen icon
                  )}
              </button>

              <button onClick={leaveRoom} title="End call" className='redbutton'>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.7785 14.3781C26.2087 14.8083 26.4503 15.3917 26.4503 16C26.4503 16.6083 26.2087 17.1917 25.7785 17.6219L25.0368 18.3636C24.5718 18.8286 23.8496 18.9172 23.2857 18.5793L20.2971 16.7855C20.0552 16.6404 19.8614 16.4271 19.7401 16.1723C19.6189 15.9176 19.5755 15.6327 19.6154 15.3534L19.79 14.13C19.8084 14.0024 19.744 13.9067 19.6678 13.8716C18.5329 13.3469 17.2975 13.0752 16.0472 13.0752C14.7969 13.0752 13.5616 13.3469 12.4267 13.8716C12.3504 13.9067 12.2866 14.0018 12.3045 14.13L12.4791 15.3534C12.519 15.6327 12.4756 15.9176 12.3543 16.1723C12.2331 16.4271 12.0393 16.6404 11.7974 16.7855L8.8088 18.5793C8.24492 18.9172 7.52265 18.8286 7.05717 18.3631L6.31597 17.6219C5.88582 17.1917 5.64417 16.6083 5.64416 16C5.64417 15.3917 5.88582 14.8083 6.31597 14.3781L7.53238 13.1617C12.2347 8.45935 19.8597 8.45935 24.5621 13.1617L25.7785 14.3781Z" fill="white"/>
              </svg>
              </button>
            </div>






            <div className="toolbar" style={{display:""}}>
        
          

              <div  style={{width:"57%"}}>
                  <div className="d-flex align-items-center" style={{ border:"2px solid #239198",background: '#222',marginLeft:"11px", color: 'white', width:"100%",fontSize:"16px", padding: '7px', borderRadius: '50px' }}>
                    <span className="mr-2" style={{ color: '#ffffff',paddingLeft:"5px",textWrap:"nowrap",width:"70px"}}>Public call</span>
                    <div className="timer">{formatTimeElapsed(timeElapsed)}</div>
                  </div>
              </div>

             {/* <Dropdown style={{ marginLeft: "21px" }} drop="up">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: "#222" }}>
                  {remoteParticipants.length + 1} users are on the call
                </Dropdown.Toggle>
                <Dropdown.Menu>
                
                  {localParticipant && (
                    <Dropdown.Item href="#/action-1">
                      {getParticipantName(localParticipant)} (You)
                    </Dropdown.Item>
                  )}
                
                  {remoteTracks.map((participant, i) => (
                    <Dropdown.Item href={`#/action-${i + 2}`} key={participant.sid}>
                      {getParticipantName(participant)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}




            <Dropdown style={{ marginLeft: "21px" }} drop="up" onClick={() => setShowParticipantsList(!showParticipantsList)}>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: "#222" }}>
                {/* {remoteTracks.length + 1 - 1 } users are on the call */}
                {new Set([...(room?.localParticipant ? [room.localParticipant.identity] : []), ...remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity)]).size} 
                {new Set([...(room?.localParticipant ? [room.localParticipant.identity] : []), ...remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity)]).size === 1 ? ' user is ' : ' users are '} 
                 on the call
              </Dropdown.Toggle>
              <Dropdown.Menu style={{display:"none"}}>
                {/* Local participant */}
                {room?.localParticipant && (
                  <Dropdown.Item href="#/action-local">
                    {getParticipantName(room.localParticipant)}
                  </Dropdown.Item>
                )}

                {/* Remote participants */}
                {/* {remoteTracks.map((remoteTrack, index) => (
                  <Dropdown.Item href={`#/action-${index + 1}`} key={remoteTrack.trackPublication.trackSid}>
                    {getParticipantName(remoteTrack.participantIdentity)}
                  </Dropdown.Item>
                ))} */}

              {[...new Set(remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity))].map((participantIdentity, index) => (
                    <Dropdown.Item href={`#/action-${index + 1}`} key={participantIdentity}>
                      {getParticipantName(participantIdentity)}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>



      </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default VideoSession;
